import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Paper from '@material-ui/core/Paper';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import { Divider, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


const styles = theme => ({
  card: {
    minWidth: 600,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      visible_to: '2',
    }
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  onSend = () => {
    var sendMessage = this.state.message;
    this.props.onAdd(sendMessage, this.state.visible_to);
    this.setState({ message: "" });

  }


  //   handleDelete = () =>{
  //     this.props.DeleteCategory(this.state.visible_to);
  // }

  handleChange = (event) => {
    this.setState({
      visible_to: event.target.value
    })
      ;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="col-l7">
        <div className="ct-div">
          <h4>Messaging History</h4>
          <div className="chat-box">
            <a >&nbsp;</a>
            <div className="cha-div">
              {this.props.messages.map((m) => {
                return <div className={"cht " + (m.me === false ? 'rit' : null)}>
                  <div>
                    {m.message}
                    <span>{m.created_at}&nbsp;&nbsp;&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.props.hideMessage(m.booking_id, m.id, m.hidden === 0 ? 1 : 0)}>{m.hidden === 0 ? 'hide' : 'show'}</a></span>

                  </div>



                </div>
              })}
            </div>

            {/* onClick={()=>this.handleDelete()} 	 */}



            <div className="frm">
              <tr>
                <td><span style={{ paddingTop: 5 }}> Send message to </span></td>
                <td><FormControl component="fieldset" className={classes.formControl}>

                  <RadioGroup
                    aria-label="visible"
                    name="gender2"
                    className={classes.group}
                    value={this.state.visible_to}
                    onChange={this.handleChange}
                    row
                  >


                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" style={{ color: "#f7a414" }} />}
                      label="Client"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" style={{ color: "#f7a414" }} />}
                      label="Supplier"
                      labelPlacement="start"
                    />

                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" style={{ color: "#f7a414" }} />}
                      label="Both"
                      labelPlacement="start"
                    />

                  </RadioGroup>

                </FormControl></td>
              </tr>
              <p> </p>
              <form>
                <textarea onChange={(e) => { this.setState({ message: e.target.value }) }} value={this.state.message} placeholder="Type your message"></textarea>
                <input type="button" onClick={() => { this.onSend(); }} value="" name="" />
              </form>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);